.ant-modal-header {
    border-radius: 15px !important;
    border: none !important;
}

.ant-modal-content {
    border-radius: 15px !important;
}

.ant-modal-footer {
    border: none !important;
    padding-bottom: 30px !important;
}

.ant-modal-body {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

@layout-body-background: #FFFFFF;@primary-color: #045DB8;@font-family: Futura,Segoe UI,Arial,sans-serif;@radio-dot-disabled-color: #9CA3AF;